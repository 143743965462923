<template>
      <body-card style="margin: 0;border: none;">
          <template v-slot:body>
            <div class="banner-img-wrapper">
                <h2 class="cover-title"> {{ $t('portal.national_tea_award_app') }}</h2>
                <img src="@/assets/images/cover-img.jpg" alt="">
            </div>
            <!-- Breadcraumb  -->
            <div>
                <b-container>
                    <ol class="breadcrumb custom-bread">
                        <li class="breadcrumb-item"><router-link to="/portal/home" target="_self" class="">{{ $t('portal.home') }}</router-link></li>
                        <li class="breadcrumb-item"><router-link to="/portal/all-services" target="_self" class="">{{ $t('portal.all_service') }}</router-link></li>
                        <li @click="$router.go(-1)" class="breadcrumb-item"><router-link to="" target="_self">{{ $t('portal.service_details') }}</router-link></li>
                        <li class="breadcrumb-item active"><span aria-current="location">{{ $t('globalTrans.application') }}</span></li>
                    </ol>
                </b-container>
            </div>
              <!-- application form start -->
            <div class="service-description">
                <b-container>
                    <body-card>
                        <div v-if="loading">
                            <Loading />
                        </div>
                        <b-overlay v-else>
                            <b-overlay :show="loading">
                            <b-col md="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form @submit.prevent="handleSubmit(finalSave)" @reset.prevent="reset">
                                    <b-row>
                                        <b-col md="12" sm="12">
                                        <b-row>
                                            <b-col>
                                                <div style="font-size:30px; background-color: #17a2b8;border-radius:5px" class="p-1 mt-1">
                                                    <h5 class="text-white text-center">
                                                        {{ $t('portal.national_tea_award_app') }}
                                                    </h5>
                                                </div>
                                            <hr>
                                            </b-col>
                                        </b-row>
                                        </b-col>
                                        <!-- award category -->
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider name="Category Type" vid="category_id" rules="required|min_value:1">
                                            <b-form-group
                                                slot-scope="{ valid, errors }"
                                                label-for="category_id">
                                                <template v-slot:label>
                                                {{ $t('national_award.category_types') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="form.category_id"
                                                :options="categoryList"
                                                id="category_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- org type -->
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider name="Org Type" vid="org_type" rules="required|min_value:1">
                                            <b-form-group
                                                slot-scope="{ valid, errors }"
                                                label-for="org_type">
                                                <template v-slot:label>
                                                {{ $t('portal.org_type') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="form.org_type"
                                                :options="orgTypeList"
                                                id="org_type"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- garden -->
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="form.org_type === 1">
                                            <ValidationProvider name="Tea Garden" vid="garden_id" rules="required|min_value:1">
                                            <b-form-group
                                                slot-scope="{ valid, errors }"
                                                label-for="garden_id">
                                                <template v-slot:label>
                                                {{ $t('teaGardenConfig.tea_garden_name') }} <span class="text-danger">*</span>
                                                </template>
                                                <v-select
                                                    plain
                                                    v-model="form.garden_id"
                                                    :options="gardenList"
                                                    id="garden_id"
                                                    :reduce="item => item.value"
                                                    label="text"
                                                    :placeholder="$t('globalTrans.select')"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :filter-by="myFilter"
                                                >
                                                </v-select>
                                                <div class="invalid-feedback d-block">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- factory -->
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="form.org_type === 2">
                                            <ValidationProvider name="Tea Factory" vid="factory_id" rules="required|min_value:1">
                                            <b-form-group
                                                slot-scope="{ valid, errors }"
                                                label-for="factory_id">
                                                <template v-slot:label>
                                                {{ $t('teaGarden.bought_leaf_factory') }} <span class="text-danger">*</span>
                                                </template>
                                                <v-select
                                                    plain
                                                    v-model="form.factory_id"
                                                    :options="factoryList"
                                                    id="factory_id"
                                                    :reduce="item => item.value"
                                                    label="text"
                                                    :placeholder="$t('globalTrans.select')"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :filter-by="myFilter"
                                                >
                                                </v-select>
                                                <div class="invalid-feedback d-block">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- company -->
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="form.org_type === 3">
                                            <ValidationProvider name="Company" vid="company_id" rules="required|min_value:1">
                                            <b-form-group
                                                slot-scope="{ valid, errors }"
                                                label-for="company_id">
                                                <template v-slot:label>
                                                {{ $t('portal.org_name') }} <span class="text-danger">*</span>
                                                </template>
                                                <v-select
                                                    plain
                                                    v-model="form.company_id"
                                                    :options="companyList"
                                                    id="company_id"
                                                    :reduce="item => item.value"
                                                    label="text"
                                                    :placeholder="$t('globalTrans.select')"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :filter-by="myFilter"
                                                >
                                                </v-select>
                                                <div class="invalid-feedback d-block">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- company written if org type getter then 3 -->
                                        <b-col xl="6" lg="6" sm="12" v-if="form.org_type > 3 || form.company_id === 100001">
                                            <ValidationProvider name="Company Name (En)" vid="company_name_en" rules="required">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="company_name_en"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('portal.org_name') }}   {{ $t('globalTrans.en') }}<span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        v-model="form.company_name_en"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xl="6" lg="6" sm="12" v-if="form.org_type > 3 || form.company_id === 100001">
                                        <ValidationProvider name="Company Name (Bn)" vid="company_name_bn" rules="required">
                                            <b-form-group
                                                slot-scope="{ valid, errors }"
                                                label-for="company_name_bn"
                                            >
                                            <template v-slot:label>
                                                {{ $t('portal.org_name') }}   {{ $t('globalTrans.bn') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    v-model="form.company_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        </b-col>
                                        <!-- applicant name-->
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="Applicant Name (En)" vid="applicant_name_en" rules="required">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="applicant_name_en"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('globalTrans.applicant_name') }}   {{ $t('globalTrans.en') }}<span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        v-model="form.applicant_name_en"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xl="6" lg="6" sm="12">
                                        <ValidationProvider name="Applicant Name (Bn)" vid="applicant_name_bn" rules="required">
                                            <b-form-group
                                                slot-scope="{ valid, errors }"
                                                label-for="applicant_name_bn"
                                            >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.applicant_name') }}   {{ $t('globalTrans.bn') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    v-model="form.applicant_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        </b-col>
                                        <!-- applicant email  -->
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="Email" vid="applicant_email" rules="required|email">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="applicant_email">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenConfig.email') }}  <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        type="email"
                                                        v-model="form.applicant_email"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- applicant mobile  -->
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="Mobile No." vid="applicant_mobile" rules="required|min:11|max:11">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="applicant_mobile">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenConfig.mobile') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="form.applicant_mobile"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- applicant designation  -->
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="Designation (En)" vid="designation" rules="required">
                                            <b-form-group
                                                slot-scope="{ valid, errors }"
                                                label-for="designation">
                                                <template v-slot:label>
                                                {{ $t('organogram.designation_en') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="designation"
                                                v-model="form.applicant_designation_en"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="Designation (Bn)" vid="designation_bn" rules="required">
                                            <b-form-group
                                                slot-scope="{ valid, errors }"
                                                label-for="designation_bn">
                                                <template v-slot:label>
                                                {{ $t('organogram.designation_bn') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="designation_bn"
                                                v-model="form.applicant_designation_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- details or standard value start here -->
                                        <!-- <h3 v-if="form.category_code > 0">{{$t('portal.standard')}}: </h3> -->
                                        <hr>
                                        <!-- category 1 -->
                                        <category-1
                                        :detail="form.detail"
                                        :categoryDetails="categoryDetails"
                                        v-if="form.category_code === 1"
                                        />
                                        <!-- category 2 -->
                                        <category-2
                                        :detail="form.detail"
                                        :categoryDetails="categoryDetails"
                                        v-if="form.category_code === 2"
                                        />
                                        <!-- category 3 -->
                                        <category-3
                                        :detail="form.detail"
                                        :categoryDetails="categoryDetails"
                                        v-if="form.category_code === 3"
                                        />
                                        <!-- category 4 -->
                                        <category-4
                                        :detail="form.detail"
                                        :categoryDetails="categoryDetails"
                                        v-if="form.category_code === 4"
                                        />
                                        <!-- category 5 -->
                                        <category-5
                                        :detail="form.detail"
                                        :categoryDetails="categoryDetails"
                                        v-if="form.category_code === 5"
                                        />
                                        <!-- category 6 -->
                                        <category-6
                                        :detail="form.detail"
                                        :categoryDetails="categoryDetails"
                                        v-if="form.category_code === 6"
                                        />
                                        <!-- category 7 -->
                                        <category-7
                                        :detail="form.detail"
                                        :categoryDetails="categoryDetails"
                                        v-if="form.category_code === 7"
                                        />
                                        <!-- category 8 -->
                                        <category-8
                                        :detail="form.detail"
                                        :categoryDetails="categoryDetails"
                                        :workerType="workerType"
                                        v-if="form.category_code === 8"
                                        />
                                    </b-row>
                                    <b-row class="text-right mb-2">
                                        <b-col>
                                            <b-button @click="back" class="btn-sm"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
                                            <b-button type="submit" variant="success" class="mr-2 btn-sm ml-3"><i class="ri-save-line"></i> {{ $t('globalTrans.submit')}}</b-button>
                                        </b-col>
                                    </b-row>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                            </b-overlay>
                        </b-overlay>
                    </body-card>
                </b-container>
            </div>
              <!-- application form end -->
              <!-- otp send modal start -->
              <div>
                <b-modal ref="mymodal" id="modal-4" size="md" :title="$t('globalTrans.otp')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                <br/>
                    <b-overlay :show="loadModal">
                    <ValidationObserver ref="otp"  v-slot="{ handleSubmit, reset }">
                        <b-form id="otp" @submit.prevent="handleSubmit(otpCheckData)" @reset.prevent="reset" >
                        <b-row>
                            <h2 class="col-lg-12 text-center" v-if="parseInt(timer) > 0">{{$t('globalTrans.timer')}} : {{ timer | minutesAndSeconds }}</h2>
                            <h2 class="col-lg-12 text-center" v-else>{{$t('globalTrans.please_resend_otp')}}</h2>
                            <b-col lg="12" sm="12">
                            <b-col lg="12" sm="12">
                                <ValidationProvider name="Otp"  vid="otp" rules="required">
                                <div slot-scope="{ valid, errors }">
                                    <b-form-input
                                    id="otp"
                                    v-model="otpCheck"
                                    :placeholder="$t('globalTrans.otp')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </div>
                                </ValidationProvider>
                            </b-col>
                            </b-col>
                            <br/>
                            <b-col lg="12" sm="12" style="padding-left: 30px;padding-top:10px">
                                <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.verify') }}</b-button>
                                <b-button type="button" variant="warning" class="mr-2 lg-3" @click="reOtpSend">{{ $t('globalTrans.resend_code') }}</b-button>
                            </b-col>
                        </b-row>
                        </b-form>
                    </ValidationObserver>
                </b-overlay>
                <br/>
                </b-modal>
              </div>
               <!-- otp send modal end -->
          </template>
      </body-card>
</template>
<script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { btbAwardCircularDetails, submitApplication, appOtpStore, appOtpVerify } from '../../api/routes'
  import Loading from './loading/Details.vue'
  import Category1 from './category-based-components/Category1.vue'
  import Category2 from './category-based-components/Category2.vue'
  import Category3 from './category-based-components/Category3.vue'
  import Category4 from './category-based-components/Category4.vue'
  import Category5 from './category-based-components/Category5.vue'
  import Category6 from './category-based-components/Category6.vue'
  import Category7 from './category-based-components/Category7.vue'
  import Category8 from './category-based-components/Category8.vue'
  import Vue from 'vue'
  import VueHtmlToPaper from 'vue-html-to-paper'
  Vue.use(VueHtmlToPaper)
  const FIVE_MINITES = 60 * 5
  export default {
      name: 'Details',
      components: {
          Loading, Category1, Category2, Category3, Category4, Category5, Category6, Category7, Category8
      },
      data () {
          return {
              teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
              form: {
                  category_code: '',
                  circular_id: this.$route.params.id,
                  garden_id: '',
                  factory_id: '',
                  company_id: '',
                  category_id: 0,
                  org_type: 0,
                  company_name_bn: '',
                  company_name_en: '',
                  applicant_name_en: '',
                  applicant_name_bn: '',
                  applicant_mobile: '',
                  applicant_email: '',
                  applicant_designation_en: '',
                  applicant_designation_bn: '',
                  detail: {
                    avg_prod_per_acor: '',
                    avg_price_of_tea_auction: '',
                    gross_annual_production: '',
                    tea_cultivation_land_percentage: '',
                    avg_price_of_tea_exported: '',
                    total_tea_export_volume: '',
                    number_of_countries_exported: '',
                    total_exported_qty_value_added_tea: '',
                    total_cultivation_area_under_25_acor: '',
                    value_added_tea_sale_qty: '',
                    max_gross_annual_production_local: '',
                    max_gross_annual_production_foreign: '',
                    number_of_workers: '',
                    number_of_house: '',
                    number_of_toilets: '',
                    number_of_tubewell: '',
                    have_hospital: '',
                    have_hospital_bed: '',
                    have_doctors: '',
                    have_compounder: '',
                    have_dispensary: '',
                    number_of_students: '',
                    have_scholarship: '',
                    have_playground: '',
                    have_disable_people: '',
                    have_training_facility: '',
                    have_day_care: '',
                    have_mothers_club: '',
                    have_pregnant_women_health_service: '',
                    workers_type: '',
                    packet_picture_attachment: '',
                    packet_details_design_attachment: '',
                    packet_uses_facilities: '',
                    ingredients_use_in_packet: ''
                  }
              },
              orgTypeList: [],
              categoryList: [],
              factoryList: [],
              gardenList: [],
              companyList: [],
              serviceDetails: '',
              categoryDetails: '',
              pageStyle: {
                  styles: [
                      'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                      `${teaGardenServiceBaseUrl}/custom.css`
                  ]
              },
             otpCheck: '',
             countShow: false,
             FIVE_MINITES: FIVE_MINITES,
             timer: 0,
             otpSentFirst: true,
             loadModal: false
          }
      },
      created () {
          this.getAppDetail()
      },
      filters: {
        minutesAndSeconds (value) {
            var minutes = Math.floor(parseInt(value, 10) / 60)
            var seconds = parseInt(value, 10) - minutes * 60
            return `${minutes}:${seconds}`
        }
       },
       mounted () {
        setInterval(() => {
            this.timer -= 1
            if (this.timer === 0) {
                this.otpDelete()
            }
        }, 1000)
       },
      watch: {
        'form.category_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.form.org_type = 0
                if (newVal === 0) {
                    this.form.category_code = 0
                    this.orgTypeList = []
                } else {
                    const category = this.categoryList.find(cat => cat.value === newVal)
                    this.categoryDetails = category.details
                    this.form.category_code = parseInt(category.category_code)

                    const categoryCodeFilters = {
                        1: [1],
                        2: [1, 2],
                        3: [3],
                        4: [4, 6],
                        5: [1],
                        6: [5],
                        7: [4, 5],
                        8: [4]
                    }
                    const filterConditions = categoryCodeFilters[parseInt(category.category_code)] || []
                    this.orgTypeList = this.orgType.filter(item => filterConditions.includes(item.value))
                }
            }
        },
        'form.org_type': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.form.garden_id = ''
                this.form.factory_id = ''
                this.form.company_id = ''
                this.form.company_name_en = ''
                this.form.company_name_bn = ''
            }
        },
        currentLocale: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                const updateList = (list) => {
                    return list.map(el => {
                        return { ...el, text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en }
                    })
                }

                this.categoryList = updateList(this.categoryList)
                this.gardenList = updateList(this.gardenList)
                this.factoryList = updateList(this.factoryList)
                this.companyList = updateList(this.companyList)
            }
        }

      },
      computed: {
          appId () {
              return this.$route.params.id
          },
          currentLocale () {
              return this.$i18n.locale
          },
          loading () {
              return this.$store.state.commonObj.loading
          },
          workerType () {
            return [
                {
                    value: 1,
                    text: this.$i18n.locale === 'en' ? 'Registered' : 'নিবন্ধিত'
                },
                {
                    value: 2,
                    text: this.$i18n.locale === 'en' ? 'Unregistered' : 'অনিবন্ধিত'
                }
            ]
          },
          orgType () {
            return [
                    {
                        value: 1,
                        text: this.$i18n.locale === 'en' ? 'Tea Garden' : 'চা বাগান'
                    },
                    {
                        value: 2,
                        text: this.$i18n.locale === 'en' ? 'Tea Factory' : 'চা কারখানা'
                    },
                    {
                        value: 3,
                        text: this.$i18n.locale === 'en' ? 'Export Company' : 'রপ্তানি কোম্পানি'
                    },
                    {
                        value: 4,
                        text: this.$i18n.locale === 'en' ? 'Person' : 'ব্যক্তি'
                    },
                    {
                        value: 5,
                        text: this.$i18n.locale === 'en' ? 'Blender' : 'ব্লেন্ডার'
                    },
                    {
                        value: 6,
                        text: this.$i18n.locale === 'en' ? 'Small Farmer' : 'ক্ষুদ্র চা চাষি'
                    }
              ]
          }
      },
      methods: {
        myFilter: (option, text, search) => {
            const temp = search.toLowerCase()
            return option.text_en.toLowerCase().indexOf(temp) > -1 ||
            option.text_bn.toLowerCase().indexOf(temp) > -1
        },
        back () {
            this.$router.go(-1)
        },
        async getAppDetail () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const params = {
                app_data: true
            }
            const result = await RestApi.getData(teaGardenServiceBaseUrl, `${btbAwardCircularDetails}/${this.appId}`, params)
            if (result.success) {
                const processArray = (arr) => {
                    return arr.map(item => {
                        const textKey = `text_${this.$i18n.locale}`
                        return { ...item, text: item[textKey] }
                    }) || []
                }

                this.data = result.data
                this.categoryList = processArray(this.data.categories)
                this.gardenList = processArray(this.data.gardens)
                this.factoryList = processArray(this.data.factories)
                this.companyList = processArray(this.data.companies)
            }

            this.$store.dispatch('mutateCommonProperties', { loading: false })
        },
        finalSave () {
            this.$swal({
                title: this.$t('configuration.final_save_mess'),
                showCancelButton: true,
                confirmButtonText: this.$t('globalTrans.yes'),
                cancelButtonText: this.$t('globalTrans.no'),
                focusConfirm: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.otpSend()
                }
            })
        },
        async otpCheckData () {
            this.loadModal = true
            let result = null
            const ottpSend = {
                mobile: this.form.applicant_mobile,
                otp_code: this.otpCheck
            }
            result = await RestApi.postData(teaGardenServiceBaseUrl, appOtpVerify, ottpSend)
            if (result.success) {
                this.loadModal = false
                this.$refs.mymodal.hide()
                this.submit()
            } else {
                    this.loadModal = false
                    this.otpCheck = ''
                    this.$toast.error({
                        title: 'Error',
                        message: 'Not Matched'
                    })
                    this.$refs.otp.setErrors(result.errors)
            }
        },
        async otpDelete () {
            let result = null
            const ottpSend = {
                mobile: this.form.applicant_mobile,
                otp_code: this.otpCheck
            }
            result = await RestApi.postData(teaGardenServiceBaseUrl, appOtpStore, ottpSend)
            if (result.success) {
                this.loadModal = false
            } else {
                this.loadModal = false
                this.$toast.error({
                title: 'Error',
                message: 'Not Matched'
                })
            }
        },
        async otpSend () {
            this.loadModal = true
            let result = null
            const data = {
                mobile: this.form.applicant_mobile
            }
            result = await RestApi.postData(teaGardenServiceBaseUrl, appOtpStore, data)
            if (result.success) {
                this.loadModal = false
                this.$toast.success({
                title: 'Success',
                message: this.$t('globalTrans.a_otp_send_your_mobile'),
                color: '#D6E09B'
                })
                this.$refs.mymodal.show()
                this.timer = this.FIVE_MINITES
            } else {
                this.loadModal = false
                this.$refs.otp.setErrors(result.errors)
            }
        },
        async reOtpSend () {
            this.loadModal = true
            let result = null
            result = await RestApi.postData(teaGardenServiceBaseUrl, appOtpStore, { mobile: this.form.applicant_mobile })
            if (result.success) {
                this.loadModal = false
                this.timer = this.FIVE_MINITES
                this.$toast.success({
                title: 'Success',
                message: this.$t('globalTrans.a_otp_send_your_mobile'),
                color: '#D6E09B'
                })
            } else {
                this.loadModal = false
                this.$refs.otp.setErrors(result.errors)
            }
        },
        async submit () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            if (this.form.org_type <= 3) {
                if (this.form.org_type === 1) {
                   const garden = this.gardenList.find(garden => garden.value === this.form.garden_id)
                   this.form.company_name_en = garden.text_en
                   this.form.company_name_bn = garden.text_bn
                } else if (this.form.org_type === 2) {
                   const factory = this.factoryList.find(factory => factory.value === this.form.factory_id)
                   this.form.company_name_en = factory.text_en
                   this.form.company_name_bn = factory.text_bn
                } else {
                   const company = this.companyList.find(company => company.value === this.form.company_id)
                   if (company?.value !== 100001) {
                    this.form.company_name_en = company.text_en
                    this.form.company_name_bn = company.text_bn
                   }
                }
            }
            result = await RestApi.postData(teaGardenServiceBaseUrl, submitApplication, this.form)

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$router.go(-1)
            } else {
                if (result.is_exist) {
                    this.$toast.error({
                        title: 'Error',
                        message: this.$i18n.locale === 'bn' ? result.msg_en : result.msg_bn,
                        color: '#D6E09B'
                    })
                }
                this.$refs.form.setErrors(result.errors)
            }
        },
        isImage (path) {
            return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
        }
      }
  }
</script>
<style>
</style>
